<style lang="scss" scoped>
  .tolttip-message{
    display:none !important;
  }
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Información general de tu suscripción digital</strong>          
          </CCardHeader>
          <CCardBody>
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Seleccione una Suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="item in suscripciones"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.product }} - {{item.id}}
                </option>
              </select>
            </div>

            <CCard>
              <CCardHeader>
                {{ nameSuscription }}
                <div class="card-header-actions">
                  <a
                    href="https://coreui.io/vue/docs/components/card-components"
                    class="card-header-action"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <!-- <small class="text-muted">Consulta</small> -->
                  </a>
                </div>
              </CCardHeader>
              <CCardBody class="p-4">
                <div v-if="suscriptionSelected['productos']" class="col-md-7 container__detalle">
                  <p v-if=" suscriptionSelected['pagomensual'] != '' && suscriptionSelected['pagomensual'] != null && suscriptionSelected['pagomensual'] != undefined " >
                    <strong>Pago: </strong
                    >S/ {{ suscriptionSelected["pagomensual"] }}
                  </p>
                  <p v-if=" suscriptionSelected['periodo'] != '' && suscriptionSelected['periodo'] != null && suscriptionSelected['periodo'] != undefined" >
                    <strong>Periodo: </strong
                    >{{ suscriptionSelected["periodo"] }}
                  </p>
                  <p v-if="suscriptionSelected['diasentregadiario'].length > 0">
                    <strong>Días de entrega de diario: </strong
                    >{{ suscriptionSelected["diasentregadiario"] }}
                  </p>
                  <p v-if=" suscriptionSelected['importepagopendiente'] != '' && suscriptionSelected['importepagopendiente'] != null && suscriptionSelected['importepagopendiente'] != undefined">
                    <strong>Importe de pago pendiente: </strong
                    >S/ {{ suscriptionSelected["importepagopendiente"] }}
                  </p>
                  <p v-if=" suscriptionSelected['fechasiguienterenovacion'] != '' &&  suscriptionSelected['fechasiguienterenovacion'] != null && suscriptionSelected['fechasiguienterenovacion'] != undefined ">
                    <strong>Fecha de siguiente renovación: </strong
                    >{{ suscriptionSelected["fechasiguienterenovacion"].split(" ")[0] }}
                  </p>
                  <p v-if=" suscriptionSelected['fechaultimopagocancelado'] != '' &&  suscriptionSelected['fechaultimopagocancelado'] != null && suscriptionSelected['fechaultimopagocancelado'] != undefined ">
                    <strong>Fecha de último pago cancelado: </strong
                    >{{ suscriptionSelected["fechaultimopagocancelado"].split(" ")[0] }}
                  </p>
                  <p v-if=" suscriptionSelected['importeultimopagocancelado'] != '' &&  suscriptionSelected['importeultimopagocancelado'] != null && suscriptionSelected['importeultimopagocancelado'] != undefined ">
                    <strong>Importe del último pago cancelado: </strong
                    >S/ {{ suscriptionSelected["importeultimopagocancelado"] }}
                  </p>
                  <p v-if=" suscriptionSelected['correo'] != '' &&  suscriptionSelected['correo'] != null && suscriptionSelected['correo'] != undefined ">
                    <strong>Correo electrónico de inicio de sesión: </strong
                    >{{ suscriptionSelected["correo"] }}
                  </p>
                </div>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/alicia_3.jpg" />
    <img class="avatar__alicia__mobile" src="../../assets/img/alicia_3.jpg" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      categoryId:"",
      subCategoryId:"",
      selectedCategoria: "0",
      show: true,
      isCollapsed: true,
      isLoading: false,
      fullPage: true,
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
    };
  },
  components: {
    Loading,
  },
  methods: {
    changeCategory(event) {
      let vm = this;
      vm.isLoading = true;

      let id = event.target.value;
      vm.categoryId = id;

      if(vm.categoryId == "1"){
          vm.subCategoryId="3";
      }else{
          vm.subCategoryId="6";
      }

      vm.suscripciones=[];
      vm.suscriptionSelected={};
      vm.nameSuscription="Aún no ha seleccionado una suscripción";

      if(id==""){
        vm.isLoading = false;
      }else{
        let data ={
            "nro_documento":localStorage.getItem("numeroDocumento"),
            "tipo_documento":localStorage.getItem("tipoDocumento"),
            "categoria":vm.categoryId,
            "subcategoria":vm.subCategoryId
          }
          console.log("Data =>",data);
        axios
          .post(
            `${process.env.VUE_APP_ROOT_API}/listSuscripciones`,
            data
          )
          .then(function (res) {
            vm.isLoading = false;
            if(res.data.data.code=="1"){
              vm.suscripciones = res.data.data.landing;
            }else{
              vm.isLoading = false;
                vm.$fire({
                  title: "Información",
                  text: "No tiene suscripciones en esta sub categoria.",
                  type: "info",
                  timer: 4000
                  }).then(r => {
                  });
            }
          }).catch(error => {
          vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en esta sub categoria.",
              type: "error",
              timer: 4000
            }).then(r => {
            });
        });
      }
    },
    
    changeSuscription(event) {

      let vm = this;
      let id = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected={};
      vm.nameSuscription="Aún no ha seleccionado una suscripción";

      if(id==""){
        vm.isLoading = false;
      }else{
        let data = {
          "idsuscripcion":id,
          "subcategoria":vm.subCategoryId,
          "nro_documento":localStorage.getItem("numeroDocumento"),
          "tipo_documento":localStorage.getItem("tipoDocumento"),
          "platformOrigin":"Landing",
          "queryType": "4"
        }

        axios
          .post(
            `${process.env.VUE_APP_ROOT_API}/consultaPaquete`,
            data
          )
          .then(function (res) {
            console.log("Res consulta paquete =>",res);
            vm.isLoading = false;
            vm.suscriptionSelected = res.data.data;
            if(vm.suscriptionSelected["productos"]){
              vm.nameSuscription = `La suscripción que ha seleccionado es: ${vm.suscriptionSelected["productos"]}`;
            }else{
              vm.nameSuscription=`Aún no ha seleccionado una suscripción`;
            }
          });
      }
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
